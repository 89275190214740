import React from "react";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import ContactSection from "../components/sections/contact-section";
import { graphql } from "gatsby";
import { Button } from "../components/elements/button";

export default function ArchitecturePage({ data }) {
    const stake = data.allDataBlockchainSectionJson.nodes[0];
	return (
		<Layout>
			<Helmet>
				<title>SG-1.online - Architecture</title>
			</Helmet>
			<main>
				<section className={"stake-single"}>
					<div className={"container"}>
						<h1 className={"mb-5 h2"}>{stake.name}</h1>
						{/* <div className={"flex flex-wrap md:flex-nowrap"}>
							<div className={"basis-full md:basis-1/2"}>
								<div className='border border-red-500'>Column-1</div>
							</div>
							<hr />
							<div className={"basis-full md:basis-1/2"}>
								<div className='border border-blue-500'>Column-2</div>
							</div>
						</div> */}
						<p className="mb-10">Details comming soon...</p>
						<a href={stake.stakeCta.link} target={"_blank"} rel={"noreferrer"}>
							<Button class="secondary large" label={stake.stakeCta.label} />
						</a>
					</div>
				</section>
			</main>
			<ContactSection />
		</Layout>
	);
}

export const query = graphql`
	query ($slug: String) {
		allDataBlockchainSectionJson(filter: { slug: { eq: $slug } }) {
			nodes {
				name
                cta {
					label
                }
				slug
				stakeCta {
					label
					link
				}
			}
		}
	}
`;
